import React from "react";

import Booknumber from "../component/Booknumber";

function FicheAdmin () {


  return ( <>

           <div className="Booknumber"><Booknumber /></div> 

     </>
  );
}
export default FicheAdmin;
